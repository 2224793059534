var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"I3Hl9q9VaB44g-kpPKeKt"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

if (process.env.DEPLOY_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://0a10650d75e6c6e8b4bc1e2cf03c313f@sentry.tutorabc.com/2',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,
    environment: process.env.DEPLOY_ENV,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    // TODO: replay 是一個好功能，但開啟的話會導致 flv request 在 unmount 的時候沒斷掉，導致有很多條 flv 串流在跑，所以先關掉，要再研究為什麼會這樣
    // integrations: [
    //   new Sentry.Replay({
    //     // Additional Replay configuration goes in here, for example:
    //     maskAllText: true,
    //     blockAllMedia: true,
    //   }),
    // ],
  })
}
