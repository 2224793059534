import handleLocationHrefDirect from '@/methods/url/handleLocationHrefDirect'
import { isClientSideShopApp } from '@/utils/navigator'
import { execute } from '@tutorabc/webview-jsbridge'

// Constants
import { STORAGE_DATA } from '@/constants/storageData'
import { envConfigs } from '@/constants/config'
import { ROUTE_PATH } from '@/constants/routePath'
import { BRIDGE_HANDLER } from '@/constants/bridgeHandler'
import { VALID_BRAND_NAME } from '@/constants/brand'

// Libs
import { resetMemberData } from '@/redux/reducers/member'

type ParamsType = {
  router: any
  dispatch: any
}

const handleLogout = ({ router, dispatch }: ParamsType) => {
  dispatch(resetMemberData())

  window.localStorage.removeItem(STORAGE_DATA.TOKEN)
  window.localStorage.removeItem(STORAGE_DATA.IS_TIMEOUT)
  window.localStorage.removeItem(STORAGE_DATA.SHOPPINGCART_ID)

  // 全站登出都回首頁 http://tp-jira.tutorabc.com/browse/SHOP-154
  const returnUrl = window.location.search
    ? `${window.location.origin}${ROUTE_PATH.HOME}${window.location.search}`
    : `${window.location.origin}${ROUTE_PATH.HOME}`
  const url = `${envConfigs?.logOutUrl}?returnUrl=${returnUrl}`
  const brandName: string = router.query?.brandName || ''
  const isValidBrandName = Object.values(VALID_BRAND_NAME).includes(brandName)

  if (isClientSideShopApp) {
    execute({
      handlerName: BRIDGE_HANDLER.TOKEN_EXPIRED,
      data: {},
    })
  } else if (!isValidBrandName) {
    handleLocationHrefDirect({ router, url })
  }
}

export default handleLogout
