import React, { useState, useEffect, useReducer } from 'react'
import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useMutation } from 'react-query'
import { useTranslation } from 'next-i18next'
import { throttle, isEmpty } from 'lodash'
import lottie from 'lottie-web'

// Libs
import handleLogout from '@/methods/handleLogout'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import useMedia from '@/hooks/useMedia'
import { handleLoginOrRegister } from '@/methods/handleLoginOrRegister'
import { setUserRecord } from '@/api/setUserRecord'
import { headerReducer, initialState, HeaderActionType } from './reducer/headerReducer'
import { handleGtmClickNavigation, handleGtmClickCheckOrder } from '@/methods/handleGtmEvent'
import useAnnouncements from '@/hooks/usePlatformAnnouncements'
import { setHeaderIsHeaderFixed, setHeaderIsHeaderBottomShowed } from '@/redux/reducers/header'

// Components
import Link from '@/components/common/Link'
import Icon from '@/assets/icons'
import Image from '@/components/common/Image'
import HeaderSearchInput from './component/SearchInput'
import SecondaryMenu from './component/SecondaryMenu'
import LanguageList from './component/LanguageList'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { ROLE_TYPE } from '@/constants/roleType'
import { LOCALE_LABEL } from '@/constants/defaultLocale'
import { GTM_LOCATION } from '@/constants/gtmEvent'
import { GtmNavigationParams, GtmClickCheckOrderClickText } from '@/constants/gtmEvent'

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

// Lotties
import headerLiveTab from '@/assets/lotties/header-live-tab.json'
import headerMallTab from '@/assets/lotties/header-mall-tab.json'

const COLOR_HEADER_PATH = [ROUTE_PATH.LIVE, ROUTE_PATH.MALL, ROUTE_PATH.HOME]
const SHOW_HEADER_BOTTOM_PATH = [
  ROUTE_PATH.LIVE,
  ROUTE_PATH.MALL,
  ROUTE_PATH.HOME,
  ROUTE_PATH.PRODUCT,
  ROUTE_PATH.SEARCH,
  ROUTE_PATH.SEARCH_LIVE,
  ROUTE_PATH.SEARCH_MERCHANT,
  ROUTE_PATH.EVENT_DETAIL,
  ROUTE_PATH.CALENDAR,
]

function Header() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const headerLiveIconRef = React.useRef<any>(null)
  const headerMallIconRef = React.useRef<any>(null)
  const [headerLiveLottieInstance, setHeaderLiveLottieInstance] = useState<any>(null)
  const [headerMallLottieInstance, setHeaderMallLottieInstance] = useState<any>(null)
  const [isHeaderFixed, setIsHeaderFixed] = useState(true)
  const [isLogoHidden, setIsLogoHidden] = useState(false)
  const [headerState, headerDispatch] = useReducer(headerReducer, initialState)
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false)
  const [canPlayHeaderTabLiveLottie, setCanPlayHeaderTabLiveLottie] = useState(false)
  const [canPlayHeaderTabMallLottie, setCanPlayHeaderTabMallLottie] = useState(false)
  const { isSecondaryMenuOpen, isMajorMenuOpen, isDropdowMenuOpen, isLanguageListOpen, isBurgerOpen } = headerState
  const router = useRouter()
  const { isDesktop, isTablet, isMobile } = useMedia()
  const fixedPlayerData = useAppSelector((state) => state.common.fixedPlayerData)
  const cartAmount = useAppSelector((state) => state.shoppingCart.cartAmount)
  const memberData = useAppSelector((state) => state.member?.memberData)
  const hasUserData = !!memberData?.memberInfoId
  const isKOL = memberData?.roles?.includes(ROLE_TYPE.KOL)

  const setUserRecordMutation = useMutation(setUserRecord)

  const isLivePage = router?.pathname === ROUTE_PATH.LIVE
  const isLiveRoomPage = router?.pathname === ROUTE_PATH.LIVE_ROOM
  const isMallPage = router?.pathname === ROUTE_PATH.MALL
  const isHomePage = router?.pathname === ROUTE_PATH.HOME
  const isSearchPage =
    router?.pathname === ROUTE_PATH.SEARCH || router?.pathname === ROUTE_PATH.SEARCH_LIVE || router?.pathname === ROUTE_PATH.SEARCH_MERCHANT
  const isHeaderColored = COLOR_HEADER_PATH.includes(router?.pathname)
  const isHeaderBottomShowed = SHOW_HEADER_BOTTOM_PATH.includes(router?.pathname)
  const isBlackIcon = !isHeaderFixed || !isHeaderColored

  const announcementList = useAnnouncements()

  const handleMajorMenuButton = () => {
    if (hasUserData) {
      handleMajorMenuOpen()
    } else {
      handleLoginOrRegister({
        isLogin: true,
        router,
        hasUserData,
        setUserRecordMutation,
        fixedPlayerData,
        isGoRedirect: isLiveRoomPage,
        gtmLocation: GTM_LOCATION.NAVIGATION,
      })
    }
  }

  const handleMajorMenuOpen = () => {
    headerDispatch({ type: HeaderActionType.HANDLE_MAJOR_OPEN })
  }

  const handleSecondaryMenuOpen = () => {
    headerDispatch({ type: HeaderActionType.HANDLE_SECONDARY_OPEN })
  }
  const handleLanguageListOpen = () => {
    headerDispatch({ type: HeaderActionType.HANDLE_LANGUAGE_LIST_OPEN })
  }

  const handleDropdownMenuClose = () => {
    headerDispatch({ type: HeaderActionType.HANDLE_DROPDOWN_CLOSE })
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsHeaderFixed(false)
      } else {
        setIsHeaderFixed(true)
      }
    }
    const throttledHandleScroll = throttle(handleScroll, 1000)
    window.addEventListener('scroll', throttledHandleScroll)

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  useEffect(() => {
    if (isDesktop) {
      const animationData1 = headerLiveTab
      const headerLiveLottieInstance = lottie.loadAnimation({
        container: headerLiveIconRef.current,
        animationData: animationData1,
        renderer: 'svg',
        loop: true,
        autoplay: false,
      })

      const animationData2 = headerMallTab
      const headerMallLottieInstance = lottie.loadAnimation({
        container: headerMallIconRef.current,
        animationData: animationData2,
        renderer: 'svg',
        loop: true,
        autoplay: false,
      })

      setHeaderLiveLottieInstance(headerLiveLottieInstance)
      setHeaderMallLottieInstance(headerMallLottieInstance)
    }
  }, [router?.pathname, isDesktop])

  useEffect(() => {
    if (!isEmpty(headerLiveLottieInstance) && canPlayHeaderTabLiveLottie) {
      headerLiveLottieInstance.loop = false
      headerLiveLottieInstance.play()
    }

    if (!isEmpty(headerMallLottieInstance) && canPlayHeaderTabMallLottie) {
      headerMallLottieInstance.loop = false
      headerMallLottieInstance.play()
    }
  }, [canPlayHeaderTabLiveLottie, canPlayHeaderTabMallLottie, headerLiveLottieInstance, headerMallLottieInstance])

  useEffect(() => {
    dispatch(setHeaderIsHeaderFixed({ data: isHeaderFixed }))
  }, [isHeaderFixed])

  useEffect(() => {
    dispatch(setHeaderIsHeaderBottomShowed({ data: isHeaderBottomShowed }))
  }, [router, isHeaderBottomShowed])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isDropdowMenuOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'initial'
      }
    }
  }, [isDropdowMenuOpen])

  return (
    <header className={cx('header')}>
      <div className={cx('header__top')}>
        <div className={cx('header__tip')}>
          <div className={cx('header__tip-icon')}>
            <Icon.Deliver data-alt={t('Header_Tip_Alt')} />
          </div>
          <h2>{announcementList.length > 0 && announcementList[0].title}</h2>
        </div>
      </div>

      <div
        className={cx('header__center', {
          'header__center--plain': isSearchInputOpen,
          'header__center--pin-to-top': isHeaderFixed && isHeaderBottomShowed,
        })}
      >
        <Link
          handleClick={() => {
            handleDropdownMenuClose()
            handleGtmClickNavigation({ clickText: GtmNavigationParams.LOGO })
          }}
          pathname={ROUTE_PATH.HOME}
        >
          <div className={cx('header-logo', { black: isBlackIcon, hidden: isLogoHidden })}>
            <Image src={'/images/svg/logo.svg'} fill alt={'TutorABC Shop'} />
          </div>
        </Link>

        {isDesktop && (
          <nav className={cx('header__home-page-tabs')}>
            <div className={cx('header__home-page-tab')}>
              <Link
                handleClick={() => {
                  handleDropdownMenuClose()
                  handleGtmClickNavigation({ clickText: GtmNavigationParams.LIVE })
                  setCanPlayHeaderTabLiveLottie(true)
                }}
                className={cx('header__home-page-tab-text')}
                pathname={ROUTE_PATH.LIVE}
                key={`${router?.pathname}-desktop-live`}
              >
                <div className={cx('header__home-page-tab-content')}>
                  <div
                    ref={headerLiveIconRef}
                    className={cx('header__home-page-tab-content-icon', { 'header__home-page-tab-content-icon--active': isLivePage })}
                  ></div>

                  {isLivePage || isHomePage ? (
                    <h1 className={cx('header__home-page-tab-content-text')}>{t('Header_Live')}</h1>
                  ) : (
                    <span className={cx('header__home-page-tab-content-text')}>{`${t('Header_Live')}`}</span>
                  )}
                </div>
              </Link>
            </div>

            <div className={cx('header__home-page-tab')}>
              <div className={cx('header__home-page-tab-background')}></div>
              <Link
                handleClick={() => {
                  handleDropdownMenuClose()
                  handleGtmClickNavigation({ clickText: GtmNavigationParams.MALL })
                  setCanPlayHeaderTabMallLottie(true)
                }}
                className={cx('header__home-page-tab-text')}
                pathname={ROUTE_PATH.MALL}
                key={`${router?.pathname}-desktop-mall`}
              >
                <div className={cx('header__home-page-tab-content')}>
                  <div
                    ref={headerMallIconRef}
                    className={cx('header__home-page-tab-content-icon', { 'header__home-page-tab-content-icon--active': isMallPage })}
                  ></div>

                  {isMallPage ? (
                    <h1 className={cx('header__home-page-tab-content-text')}>{t('Header_Mall')}</h1>
                  ) : (
                    <div className={cx('header__home-page-tab-content-text')}>{t('Header_Mall')}</div>
                  )}
                </div>
              </Link>
            </div>
          </nav>
        )}

        <div className={cx('header__search', { 'header__search--outlined': !isSearchPage })}>
          {!isSearchPage && <HeaderSearchInput setIsLogoHidden={setIsLogoHidden} setIsSearchInputOpen={setIsSearchInputOpen} />}
        </div>

        <div className={cx('header-action')} style={{ cursor: 'default' }}>
          {isDesktop && (
            <div className={cx('header-action-language')} onClick={handleLanguageListOpen}>
              <div className={cx('header-action-language-icon')}>
                <Icon.Earth />
              </div>
              <div className={cx('header-action-language-text')}>{LOCALE_LABEL[router.locale as string]}</div>
            </div>
          )}

          <Link
            handleClick={handleDropdownMenuClose}
            className={cx('header-action-shopbag', { 'header-action-shopbag--has-not-login': !hasUserData })}
            pathname={ROUTE_PATH.SHOPPINGCART}
          >
            <div className={cx('header-action-shopbag-wrapper')}>
              <div className={cx('header-action-shopbag-icon')}>
                {isSearchInputOpen && (isTablet || isMobile) ? <Icon.ShoppingCartBlack /> : <Icon.ShoppingCartWhite />}
              </div>
              <div className={cx('header-action-shopbag-number', { 'header-action-shopbag-number--plain': isSearchInputOpen, black: isBlackIcon })}>
                {cartAmount}
              </div>
            </div>
          </Link>

          {isDesktop && (
            <div
              onClick={handleMajorMenuButton}
              className={cx('header-action-login', { 'header-action-login--login': hasUserData, 'header-action-login--has-not-login': !hasUserData })}
            >
              <div className={cx('header-action-login-wraper', { 'header-action-login-wraper--login': hasUserData })}>
                <Icon.Human />
              </div>
            </div>
          )}

          <div onClick={handleSecondaryMenuOpen} className={cx('header-action-burger', { 'header-action-burger--plain': isSearchInputOpen })}>
            <div className={cx('header-action-burger-wraper', { 'header-action-burger-wraper--plain': isSearchInputOpen })}>
              {isBurgerOpen ? <Icon.CloseSM /> : <Icon.Burger />}
            </div>
          </div>

          <nav
            onClick={handleDropdownMenuClose}
            className={cx('header__dropdown-menu', {
              'header__dropdown-menu--with-header-tip': isTablet || isMobile,
              'header__dropdown-menu--without-header-tip':
                ((isTablet || isMobile) && !isHeaderFixed && isHeaderBottomShowed) ||
                ((isTablet || isMobile) && !isHeaderBottomShowed) ||
                isLiveRoomPage,
              'header__dropdown-menu--open': isDropdowMenuOpen,
            })}
          >
            <ul
              onClick={(e) => e.stopPropagation()}
              className={cx('header__dropdown-menu-list', { 'header__dropdown-menu-list--open': isDropdowMenuOpen })}
            >
              {/* desktop menu */}
              {isDesktop && isMajorMenuOpen && (
                <>
                  <Link
                    className={cx('header__dropdown-menu-item')}
                    pathname={ROUTE_PATH.ORDERS}
                    handleClick={() => {
                      handleDropdownMenuClose()
                      handleGtmClickCheckOrder({ clickText: t(GtmClickCheckOrderClickText.MY_ORDER), location: GTM_LOCATION.NAVIGATION })
                    }}
                  >
                    {t('Header_My_Orders')}
                  </Link>
                  {isKOL && (
                    <Link className={cx('header__dropdown-menu-item')} pathname={ROUTE_PATH.MY_LIVE_PAST} handleClick={handleDropdownMenuClose}>
                      {t('Header_My_Live')}
                    </Link>
                  )}
                  <Link
                    className={cx('header__dropdown-menu-item')}
                    pathname={ROUTE_PATH.ME}
                    handleClick={() => {
                      handleDropdownMenuClose()
                    }}
                  >
                    {t('Header_Member_Area')}
                  </Link>
                  <div
                    onClick={() => {
                      handleLogout({ router, dispatch })
                    }}
                    className={cx('header__dropdown-menu-item')}
                  >
                    {t('Header_Logout')}
                  </div>
                </>
              )}

              {isDesktop && isSecondaryMenuOpen && <SecondaryMenu handleMenuClose={handleDropdownMenuClose} />}
              {isDesktop && isLanguageListOpen && <LanguageList headerDispatch={headerDispatch} />}
              {/* desktop menu */}

              {/* mobile menu */}
              <>
                {(isTablet || isMobile) && (
                  <>
                    {isLiveRoomPage && (
                      <div className={cx('header__dropdown-menu-item')}>
                        <div className={cx('header__dropdown-menu-mobile-home-page-tabs')}>
                          <Link
                            handleClick={handleDropdownMenuClose}
                            className={cx('header__dropdown-menu-mobile-home-page-tab', 'header__dropdown-menu-mobile-home-page-tab--live')}
                            pathname={ROUTE_PATH.LIVE}
                          >
                            <div
                              className={cx(
                                'header__dropdown-menu-mobile-home-page-tab-placeholder',
                                'header__dropdown-menu-mobile-home-page-tab-placeholder--live',
                              )}
                            >
                              {t('Header_Live')}
                            </div>
                          </Link>
                          <Link
                            handleClick={() => {
                              handleDropdownMenuClose()
                            }}
                            className={cx('header__dropdown-menu-mobile-home-page-tab', 'header__dropdown-menu-mobile-home-page-tab--product')}
                            pathname={ROUTE_PATH.MALL}
                          >
                            <div
                              className={cx(
                                'header__dropdown-menu-mobile-home-page-tab-placeholder',
                                'header__dropdown-menu-mobile-home-page-tab-placeholder--product',
                              )}
                            >
                              {t('Header_Mall')}
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() =>
                        handleLoginOrRegister({
                          isLogin: true,
                          router,
                          hasUserData,
                          setUserRecordMutation,
                          fixedPlayerData,
                          isGoRedirect: isLiveRoomPage,
                          gtmLocation: GTM_LOCATION.NAVIGATION,
                        })
                      }
                      className={cx('header__dropdown-menu-item', { 'header__dropdown-menu-item--login': hasUserData })}
                    >
                      <div className={cx('header__dropdown-menu-mobile-login')}>
                        <div
                          className={cx('header__dropdown-menu-mobile-login-icon', {
                            'header__dropdown-menu-mobile-login-icon--login': hasUserData,
                          })}
                        >
                          <Icon.Human />
                        </div>
                        <div className={cx('header__dropdown-menu-mobile-login-username')}>
                          {hasUserData ? memberData?.nickName : t('Header_Login')}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* mobile menu */}
                {!isLanguageListOpen && (isTablet || isMobile) && hasUserData && (
                  <div className={cx('header__dropdown-menu-mobile-list-major')}>
                    <Link
                      handleClick={() => {
                        handleDropdownMenuClose()
                        handleGtmClickCheckOrder({ clickText: t(GtmClickCheckOrderClickText.MY_ORDER), location: GTM_LOCATION.NAVIGATION })
                      }}
                      className={cx('header__dropdown-menu-mobile-item')}
                      pathname={ROUTE_PATH.ORDERS}
                    >
                      {t('Header_My_Orders')}
                    </Link>
                    {isKOL && (
                      <Link
                        className={cx('header__dropdown-menu-mobile-item')}
                        pathname={ROUTE_PATH.MY_LIVE_PAST}
                        handleClick={handleDropdownMenuClose}
                      >
                        {t('Header_My_Live')}
                      </Link>
                    )}
                    <Link
                      className={cx('header__dropdown-menu-mobile-item')}
                      pathname={ROUTE_PATH.ME}
                      handleClick={() => {
                        handleDropdownMenuClose()
                      }}
                    >
                      {t('Header_Member_Area')}
                    </Link>
                    <div
                      onClick={() => {
                        handleLogout({ router, dispatch })
                        handleGtmClickNavigation({ clickText: t(GtmNavigationParams.MEMBER_LOGOUT, { lng: 'zh-tw' }) as GtmNavigationParams })
                      }}
                      className={cx('header__dropdown-menu-mobile-item')}
                    >
                      {t('Header_Logout')}
                    </div>
                  </div>
                )}

                {/* mobile menu */}
                {(isTablet || isMobile) && (
                  <>
                    {isLanguageListOpen ? (
                      <LanguageList headerDispatch={headerDispatch} />
                    ) : (
                      <SecondaryMenu handleMenuClose={handleDropdownMenuClose} headerDispatch={headerDispatch} />
                    )}
                  </>
                )}
              </>
              {/* mobile menu */}
            </ul>
          </nav>
        </div>
      </div>

      {/* mobile 首頁切換 tab */}
      {(isTablet || isMobile) && !isLiveRoomPage && (
        <div className={cx('header__bottom', { 'header__bottom--pin-to-center': isHeaderFixed && isHeaderBottomShowed })}>
          <nav className={cx('header__mobile-home-page-tabs')}>
            <Link
              handleClick={handleDropdownMenuClose}
              className={cx('header__mobile-home-page-tab', 'header__mobile-home-page-tab--live', {
                'header__mobile-home-page-tab--selected': isLivePage || isHomePage,
              })}
              pathname={ROUTE_PATH.LIVE}
              key={`${router?.pathname}-mobile-live`}
            >
              {isLivePage || isHomePage ? (
                <h1
                  className={cx('header__mobile-home-page-tab-placeholder', 'header__mobile-home-page-tab-placeholder--live', {
                    'header__mobile-home-page-tab-placeholder--selected': isLivePage || isHomePage,
                  })}
                >
                  {t('Header_Live')}
                </h1>
              ) : (
                <div
                  className={cx('header__mobile-home-page-tab-placeholder', 'header__mobile-home-page-tab-placeholder--live', {
                    'header__mobile-home-page-tab-placeholder--selected': isLivePage || isHomePage,
                  })}
                >
                  {t('Header_Live')}
                </div>
              )}
            </Link>
            <Link
              handleClick={() => {
                handleDropdownMenuClose()
              }}
              className={cx('header__mobile-home-page-tab', 'header__mobile-home-page-tab--product', {
                'header__mobile-home-page-tab--selected': isMallPage,
              })}
              pathname={ROUTE_PATH.MALL}
              key={`${router?.pathname}-mobile-mall`}
            >
              {isMallPage ? (
                <h1
                  className={cx('header__mobile-home-page-tab-placeholder', 'header__mobile-home-page-tab-placeholder--product', {
                    'header__mobile-home-page-tab-placeholder--selected': isMallPage,
                  })}
                >
                  {t('Header_Mall')}
                </h1>
              ) : (
                <div
                  className={cx('header__mobile-home-page-tab-placeholder', 'header__mobile-home-page-tab-placeholder--product', {
                    'header__mobile-home-page-tab-placeholder--selected': isMallPage,
                  })}
                >
                  {t('Header_Mall')}
                </div>
              )}
            </Link>
          </nav>
        </div>
      )}
      {/* mobile 首頁切換 tab */}
    </header>
  )
}

export default Header
