import classnames from 'classnames/bind'

// Libs

// Components
import Icon from '@/assets/icons'
import Link from '@/components/common/Link'

// Constants

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

function SimpleHeader() {
  return (
    <header className={cx('header')}>
      <Link url='https://www.strawberrynet.com/' isOpen>
        <div className={cx('header-logo')}>
          <Icon.LogoStrawberry />
        </div>
      </Link>
    </header>
  )
}

export default SimpleHeader
