import classnames from 'classnames/bind'
import Image from '@/components/common/Image'
import { isEmpty } from 'lodash'
import { useAppSelector } from '@/redux/hooks'

// Libs
import useMedia from '@/hooks/useMedia'

// Components
import Link from '@/components/common/Link'

// Constants
import { EXTERNAL_LINK } from '@/constants/externalLink'

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

function SimpleHeader() {
  const { isMobile, isTablet } = useMedia()
  const memberData = useAppSelector((state) => state?.member?.memberData)

  return (
    <header className={cx('header')}>
      <div className={cx('header__wrapper')}>
        <Link url={EXTERNAL_LINK.PCHOME_OFFICIAL_SITE} isOpen>
          <div className={cx('header-logo')}>
            <Image src={`/images/svg/logo_pchome_${isTablet || isMobile ? 'm' : 'd'}.svg`} fill alt={'PCHome'} />
          </div>
        </Link>
        {!isEmpty(memberData.refCode) && <div className={cx('header__code')}>ID: {memberData.refCode}</div>}
      </div>
    </header>
  )
}

export default SimpleHeader
