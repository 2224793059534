import { envConfigs } from '@/constants/config'

export const socialReferralCookieKey = 'social_referral'

export enum SocialReferralKey {
  socialReferralFrom = 'social_referral_from',
  socialReferralId = 'social_referral_id',
}

export const COOKIE_DATA = {
  PCHOME_UUID: 'pchome_uuid',
  PCHOME_NAME_EXPIRED: 'pchome_name_expired',
}

const getDomainUrl = () => {
  const domainUrl = envConfigs.domainUrl
  const domainSegments = domainUrl.split('.')
  let result = null

  domainSegments.shift()
  result = `.${domainSegments.join('.')}`

  return result
}

export const COOKIE_OPTIONS = {
  PCHOME: {
    DOMAIN: getDomainUrl(),
  },
}
